.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    padding: 24px;
    border-radius: 8px;
    width: 420px;
    text-align: center;
    position: relative;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #666;
}

.modal-title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.modal-subtitle {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
}

.input-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 15px;

    label {
        font-size: 14px;
        font-weight: 600;
        color: #333;
        margin-bottom: 5px;
    }

    .amount-input {
        border-radius: 8px;
    }
}

.action-buttons {
    margin-top: 1rem;
}

.merchant_amt-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.amount-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;

    .amount-btn {
        min-width: 60px;
        border-radius: 8px;
        font-weight: 600;
        border: 2px solid #0095ff;
        color: #0095ff;

        &:hover {
            background-color: #0095ff;
            color: white;
        }

        &.active {
            background-color: #0095ff;
            color: #fff;
            border: none;
        }
    }
}

.apply-coupon {
    color: #007bff;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 20px;
}

.action-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .primary-btn {
        background-color: #0052cc;
        color: #fff;
        font-weight: 600;
        border-radius: 8px;
        padding: 10px;
    }
}

.bottom-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;

    .secondary-btn {
        border-radius: 8px;
        border: 2px solid #0095ff;
        color: #0095ff;
        font-weight: 600;

        &:hover {
            background-color: #0095ff;
            color: #fff;
        }
    }
}

.recharge-button {
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    font-weight: 600;
}

.neft-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .formContainer {
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        width: 400px;
        max-width: 90%;
    }

    .bank-details {
        background: #f8f9fa;
        padding: 15px;
        border-radius: 6px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.6;
    }

    .input-container {
        margin-bottom: 15px;

        label {
            font-weight: 500;
            display: block;
            margin-bottom: 5px;
        }

        .MuiOutlinedInput-root {
            width: 100%;
        }
    }

    .action-buttons {
        display: flex;
        justify-content: flex-end;

        .primary-btn {
            background: #3b82f6;
            color: #fff;
            text-transform: none;
            font-weight: 600;

            &:hover {
                background: #2563eb;
            }
        }
    }
}

.neft-container {
    display: flex;
    // align-items: center;
    justify-content: space-between;
}