// MyComponent.scss
@import 'variables';
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Rubik", sans-serif !important;
  background-color: $body-bg !important;
}

.css-l086p4-MuiImageListItem-root .MuiImageListItem-img {
  height: 40px !important;
}

.column-form {
  max-width: 100%;
}

.primary-button {
  font-size: 0.75rem !important;
  padding: 5px 8px;
}

.table-common-button {
  height: fit-content;
  font-size: 0.75rem !important;
  padding: 2px 5px !important;
}

.edit-button {
  background-color: #004b8d !important;
  color: #fff !important;

  &:hover {
    background-color: #004b8d !important;
  }
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;

  .primary-button {
    // padding: 10px 15px; // Adjust padding if necessary
    margin-left: 0; // Remove margin from individual buttons
  }
}

.side-panel-form {
  margin-top: 1rem;
}

.order-strip {
  padding: 0.2rem 1rem !important;
  justify-content: center !important;
}
.MuiAppBar-root{
  background: transparent !important;
}
.dashboard-tabs {
  margin-bottom: 1rem;
  .MuiTabs-root {
    background: transparent !important;
  }
  .MuiTabs-flexContainer {
    justify-content: center !important;
  }
}
.top-search-form {
  display: flex;
  align-items: center;

  .top-search {
    background: #fff;
    border-radius: 2rem;



    .select-input {
      margin: .5rem 0 !important;
      border-radius: 0 !important;
      background: transparent !important;

      .MuiSelect-select {
        font-size: .75rem !important;
        padding: 5.5px 32px 5.5px 14px !important;
        border-radius: 0;
        border: 0;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }

    .MuiInputBase-root {
      background: transparent;
      background-color: transparent !important;
    }
  }
}

.order-details {
  .select-input {
    margin: .5rem 0 !important;

    .MuiSelect-select {
      font-size: .75rem !important;
      padding: 5.5px 14px !important;
    }
  }

}

.react-datepicker-wrapper {
  display: block !important;
  margin: 0.5rem 0 !important;

  .form-control {
    width: 100%;
    font-size: 0.8rem;
  }
}

.ndr-summary {
  background: transparent;
  // .MuiPaper-root{
  //   background: transparent;
  //   box-shadow: 0;
  // }
}

.pickup-request-form {
  .paper {
    width: 100%;

    .column-form {
      width: 100%;
    }
  }
}

.pricing-from {

  input {
    min-width: 80px !important;
  }


  .select-input {
    min-width: 80px !important;
  }
}

.summary-table {
  .summary-badge {
    font-size: 0.7rem;
    white-space: nowrap;
  }
}

.formContainer {
  margin-top: 1rem;
  background-color: $white !important;
  padding: 1rem;

  input {
    padding: 5.5px 14px !important;
  }

  label {
    font-size: 0.75rem !important;
    top: -6px !important;
  }

  .select-input {
    margin: 0.5rem 0 !important;

    .MuiSelect-select {
      padding: 5.5px 14px !important;
      font-size: 0.75rem !important;
    }
  }

  .css-1nrlq1o-MuiFormControl-root {
    margin: 0.5rem !important;
  }

  .paper {
    padding: 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .css-f62srg-MuiFormLabel-root {
    font-size: 0.75rem !important;
  }

  .css-1che66z-MuiTypography-root {
    font-size: 0.75rem !important;
  }

  .css-u4tvz2-MuiFormLabel-root {
    font-size: 0.75rem !important;
  }

  .column-form {
    .custom-input {
      margin: 0.5rem 0 !important;
    }
  }
}

.filter-style {
  display: flex;
  align-items: center;

  .MuiStack-root {
    padding-top: 0 !important;
    margin-left: 0.5rem;
  }

  input {
    padding: 7.5px 14px !important;
  }

  label {
    top: -8px !important;
  }

  .css-qiwgdb.css-qiwgdb.css-qiwgdb {
    padding-right: 32px !important;
  }

  .MuiSelect-select {
    padding: 7.5px 14px !important;
  }
}

.filter-date {
  input {
    padding: 7.5px 14px !important;
  }
}

.order-details {
  .select-input {
    margin: 0.5rem 0 !important;

    .MuiSelect-select {
      padding: 5.5px 14px !important;
      font-size: 0.75rem !important;
    }
  }
}

.formCreate {
  margin-top: 1rem;
  background-color: $white !important;
  padding: 1rem;

  .column-form {
    width: 100%;
  }

  input {
    padding: 5.5px 14px !important;
  }

  label {
    font-size: 0.75rem !important;
    top: -6px !important;
  }

  .select-input {
    margin: 0.5rem 0 !important;

    .MuiSelect-select {
      padding: 5.5px 14px !important;
      font-size: 0.75rem !important;
    }
  }

  .css-1nrlq1o-MuiFormControl-root {
    margin: 0.5rem 0 !important;
  }

  .paper {
    padding: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .css-f62srg-MuiFormLabel-root {
    font-size: 0.75rem !important;
  }

  .css-1che66z-MuiTypography-root {
    font-size: 0.75rem !important;
  }

  .css-u4tvz2-MuiFormLabel-root {
    font-size: 0.75rem !important;
  }

  .column-form {
    .custom-input {
      margin: 0.5rem 0 !important;
    }
  }
}

.formSearch {
  background-color: $white !important;

  .column-form {
    width: 100%;
    display: flex;
    align-items: center;

    button {
      max-height: 30px;
      min-width: 80px;
    }
  }

  input {
    padding: 5.5px 14px !important;
  }

  label {
    font-size: 0.75rem !important;
    top: -6px !important;
  }

  .select-input {
    margin: 0.5rem 0 !important;

    .MuiSelect-select {
      padding: 5.5px 14px !important;
      font-size: 0.75rem !important;
    }
  }


  .paper {
    padding: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .css-f62srg-MuiFormLabel-root {
    font-size: 0.75rem !important;
  }

  .css-1che66z-MuiTypography-root {
    font-size: 0.75rem !important;
  }

  .css-u4tvz2-MuiFormLabel-root {
    font-size: 0.75rem !important;
  }

}

.main-menu {
  margin-top: 1rem !important;

  .MuiListItem-root.MuiListItem-gutters.css-8j6vt7-MuiListItem-root {
    font-weight: 600;
    margin: 0.5rem 0;
  }

  .active {
    .MuiListItem-root.MuiListItem-gutters.css-8j6vt7-MuiListItem-root {
      color: #004b8d;

      .css-6zdkjc-MuiListItemIcon-root {
        color: #004b8d;

      }
    }
  }
}

.table-main {
  .css-1howxi1-MuiTableCell-root {
    padding: 4px 8px;
    color: rgb(40, 62, 120);
    font-weight: 600;
    font-size: 0.75rem;

    .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
      color: rgb(40, 62, 120);
    }
  }
}

.table-main {

  td,
  th {
    font-size: 0.75rem !important;
    padding: 3px 7px !important;
  }

  thead {
    th {
      color: #9babc5 !important;

      span {
        color: #9babc5 !important;
      }
    }
  }
}

.page-title {
  text-align: left;
}

.filter-select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8.5px 32px 8.5px 14px !important;
  }
}

.filter-field {
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
  }

  .css-14jqoni-MuiFormLabel-root-MuiInputLabel-root {
    top: -8px;
  }
}

.merchant-main {
  padding: $page-padding;

  @media (max-width: 768px) {
    padding: 100px 15px 0 75px;
  }

  .merchant-box {
    width: 100%;
    background-color: $white;
    // box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, .1);
    box-shadow: none;
    border-radius: .375rem !important;

    .MuiPaper-root {
      box-shadow: none !important;
    }
  }

  // &:hover {
  //   background-color: darken($primary-color, 10%);
  // }
}

.tab-section {
  .MuiTabs-scroller {
    overflow: auto !important;
  }
}

.header-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding-top: 0; */
  /* padding-bottom: 0; */
  padding-left: 1rem;
  padding-right: 2rem;
  box-shadow: none !important;
  outline: 0;
  border: 0;
  background-color: transparent !important;
}

.lr-section {
  padding-right: 1.2rem;
  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;

    svg {
      font-size: 1rem;
    }
  }
}

.lr-section {
  padding-right: 1.2rem;
  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;

    svg {
      font-size: 1rem;
    }
  }
}


.remittance-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .remittance-item {
    text-align: center;
    border-right: 1px solid #e0e0e0;

    &:last-child {
      border-right: none;
    }

    h6 {
      font-size: 1rem;
      color: #888;
    }

    .amount {
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 5px;
      }
    }
  }
}