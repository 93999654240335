   $blue: #007bff;
   $indigo: #6610f2;
   $purple: #7367f0;
   $pink: #e83e8c;
   $red: #ea5455;
   $orange: #fd7e14;
   $yellow: #ff9f43;
   $green: #28c76f;
   $teal: #20c997;
   $cyan: #00cfe8;
   $black: #4b465c;
   $white: #fff;
   $gray: rgba(75, 70, 92, 0.6);
   $gray-dark: rgba(75, 70, 92, 0.8);
   $gray-25: rgba(75, 70, 92, 0.015);
   $gray-50: rgba(75, 70, 92, 0.03);
   $primary: #7367f0;
   $secondary: #a8aaae;
   $success: #28c76f;
   $info: #00cfe8;
   $warning: #ff9f43;
   $danger: #ea5455;
   $light: #dfdfe3;
   $dark: #4b4b4b;
   $gray: rgba(75, 70, 92, 0.05);
   $primary-rgb: 115, 103, 240;
   $secondary-rgb: 168, 170, 174;
   $success-rgb: 40, 199, 111;
   $info-rgb: 0, 207, 232;
   $warning-rgb: 255, 159, 67;
   $danger-rgb: 234, 84, 85;
   $light-rgb: 223, 223, 227;
   $dark-rgb: 75, 75, 75;
   $gray-rgb: 75, 70, 92;
   $white-rgb: 255, 255, 255;
   $black-rgb: 75, 70, 92;
   $body-color-rgb: 111, 107, 125;
   $body-bg-rgb: 248, 247, 250;
   $font-sans-serif: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
   $font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
   $gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
   $root-font-size: 16px;
   $body-font-family: var(--bs-font-sans-serif);
   $body-font-size: 0.9375rem;
   $body-font-weight: 400;
   $body-line-height: 1.47;
   $body-color: #6f6b7d;
   $body-bg: #f8f7fa;
   $border-width: 1px;
   $border-style: solid;
   $border-color: #dbdade;
   $border-color-translucent: rgba(75, 70, 92, 0.175);
   $border-radius: 0.375rem;
   $border-radius-sm: 0.25rem;
   $border-radius-lg: 0.5rem;
   $border-radius-xl: 0.625rem;
   $border-radius-2xl: 2rem;
   $border-radius-pill: 50rem;
   $link-color: #7367f0;
   $link-hover-color: #685dd8;
   $code-color: #e83e8c;
   $highlight-bg: #ffecd9;
   $body-bg: #f7faff;
   $page-padding: 150px 30px 0 100px;